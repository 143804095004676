<template>
  <ag-modal-container
    title="Ajouter une opération"
    width="600px"
    @close="back"
  >
    <operation-form
      v-model="value"
      @validate="isValid = $event"
    />

    <template v-slot:footer>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="!isValid"
        color="accent"
        class="ml-5"
        @click="add"
        v-text="'Ajouter'"
      />
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import OperationForm from '../../components/OperationForm'

export default {
  name: 'OperationAddContainer',
  components: {
    AgModalContainer,
    OperationForm,
  },
  props: {
    operationId: {
      type: String,
      required: true,
    },
    orderOperationLength: {
      type: Number,
      required: true,
    },
    unorderOperationLength: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isValid: false,
    loading: false,
    value: {
      operationType: null,
    },
  }),
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$emit('back')
    },
    async add () {
      this.loading = true
      try {
        const newOrder = this.value.operationType.control
          ? this.unorderOperationLength + 1
          : this.orderOperationLength + 1
        this.value.order = newOrder
        await Repositories.operation.post({
          order: newOrder,
          operationType: this.value.operationType,
          partNumber: this.$route.params.partNumberId,
        })
        this.showSuccessNotification('L\'opération à bien été ajouté')
        this.$emit('add')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
  },
}
</script>
