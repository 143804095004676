<template>
  <ag-modal-container
    title="Ajouter des relevés"
    width="600px"
    @close="back"
  >
    <record-form
      v-model="value"
      @validate="isValid = $event"
    />

    <template v-slot:footer>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="!isValid"
        color="accent"
        class="ml-5"
        @click="add"
        v-text="'Ajouter'"
      />
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import RecordForm from '../../components/RecordForm'

export default {
  name: 'RecordAddContainer',
  components: {
    AgModalContainer,
    RecordForm,
  },
  props: {
    operationId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isValid: false,
    loading: false,
    value: {
      recordList: null,
    },
  }),
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$emit('back')
    },
    async add () {
      this.loading = true
      try {
        const operation = await Repositories.operation.get(this.operationId)

        for (const record of this.value.recordList) {
          if (record.level === 'Pièces') {
            operation.partsRecords.push(record._id)
          }
          else {
            operation.batchRecords.push(record._id)
          }
        }
        await Repositories.operation.patch(operation)

        this.showSuccessNotification('Les relevés ont bien été ajoutés')
        this.$emit('add')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
  },
}
</script>
