<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <v-autocomplete
        v-model="internalValue.recordList"
        :rules="$options.rules.recordList"
        multiple
        chips
        deletable-chips
        label="Relevés"
        :items="filteredRecordList"
        item-text="name"
        return-object
        @input="change"
      >
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title v-text="data.item.name" />
            <v-list-item-subtitle v-text="data.item.type" />
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-form>
  </div>
</template>

<script>
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'

export default {
  name: 'RecordForm',
  components: { },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    internalValue: null,
    mainFormValid: false,
    recordTypeList: [],
    partNumber: null,
  }),
  computed: {
    isValidRecord () {
      return this.mainFormValid
    },
    filteredRecordList () {
      if (!this.partNumber) return this.recordTypeList
      return this.recordTypeList.filter(record => record.partType._id === this.partNumber.partType)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
        this.$emit('validate', this.isValidRecord)
      },
    },
    isValidRecord: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidRecord)
      },
    },
  },
  async mounted () {
    this.recordTypeList = await Repositories.recordType.getList({ archived: false })
    this.partNumber = await Repositories.partNumber.get(this.$route.params.partNumberId)
  },
  methods: {
    change () {
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidRecord)
    },
  },
  rules: {
    recordList: [
      FormRules.required('requis'),
    ],
  },
}
</script>
