<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    item-key="_id"
    :show-select="false"
    :disable-pagination="true"
    :hide-default-footer="true"
  >
    <template v-slot:body>
      <draggable
        :list="internalValue"
        tag="tbody"
        @change="change"
      >
        <tr
          v-for="(record, index) in internalValue"
          :key="index"
        >
          <td class="text--center">
            <v-icon
              small
              class="page__grab-icon"
              style="cursor: move"
            >
              drag_indicator
            </v-icon>
          </td>
          <td class="text--center">
            {{ index + 1 }}
          </td>
          <td class="text--center">
            {{ record.name }}
          </td>
          <td class="text--center">
            <ag-confirm-dialog
              title="Confirmation"
              message="Souhaitez-vous supprimer ce relevé"
              cancel-label="Annuler"
              confirm-label="Supprimer"
              validation-btn-color="error"
              @confirm="$emit('delete', record)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="error"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </template>
            </ag-confirm-dialog>
          </td>
        </tr>
      </draggable>
    </template>
  </v-data-table>
</template>

<script>
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import draggable from 'vuedraggable'
import Helpers from 'src/utils/helpers'

export default {
  name: 'RecordList',
  components: {
    draggable,
    AgConfirmDialog,
  },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    internalValue: null,
  }),
  computed: {
    headers () {
      return [
        {
          text: '',
          sortable: false,
          align: 'center',
          width: '30px',
        },
        {
          text: 'Position',
          sortable: false,
          align: 'center',
          width: '30px',
        },
        {
          text: 'Nom',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Supprimer',
          align: 'center',
          sortable: false,
          width: '30px',
        },
      ]
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
      },
    },
  },
  methods: {
    change () {
      this.$emit('input', this.internalValue)
    },
  },
}
</script>
<style scoped>
td {
  text-align: center !important;
}
</style>
