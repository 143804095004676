<template>
  <v-expansion-panels popout flat focusable>
    <draggable
      v-model="internalValue"
      class="
      v-item-group
      theme--light
      v-expansion-panels
      v-expansion-panels--popout
      v-expansion-panels--flat
      "
      ghost-class="sortable-ghost"
      handle=".sortable-handler"
      @input="change"
    >
      <v-expansion-panel
        v-for="(operation, index) in internalValue"
        :key="`instruction-${operation.order}`"
      >
        <v-divider v-if="index" />
        <v-expansion-panel-header>
          <template v-slot:default="{ }">
            <slot name="header" :operation="operation" />
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content eager>
          <slot name="content" :operation="operation" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </draggable>
  </v-expansion-panels>
</template>

<script>
import draggable from 'vuedraggable'
import Helpers from 'src/utils/helpers'

export default {
  name: 'OperationList',
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    internalValue: [],
  }),
  computed: { },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
      },
    },
  },
  methods: {
    change () {
      this.internalValue.forEach((operation, index) => {
        operation.order = index + 1
      })
      this.$emit('input', this.internalValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.sortable-ghost {
  opacity: 0.5;
}
.list-container {
  width: 100%;
  max-width: 100%;
}
</style>
