<template>
  <v-container>
    <ag-card-container
      v-if="orderOperationList.length"
      flat
      fluid
      title="Opérations successives"
      no-content-padding
    >
      <operation-list
        v-model="orderOperationList"
        @input="update(orderOperationList)"
      >
        <template v-slot:header="{ operation }">
          <v-row align="center">
            <v-col class="shrink">
              <v-icon
                class="sortable-handler"
                style="cursor: move;"
              >
                drag_indicator
              </v-icon>
            </v-col>
            <v-col>
              {{ operation.order }} - {{ operation.operationType.name }}
              <ag-confirm-dialog
                title="Confirmation"
                :message="removeMessage(operation)"
                cancel-label="Annuler"
                confirm-label="Supprimer"
                validation-btn-color="error"
                @confirm="removeOperation(operation)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="loadingRemove"
                    color="error"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </template>
              </ag-confirm-dialog>
            </v-col>
          </v-row>
        </template>
        <template v-slot:content="{ operation }">
          <operation-update-container :operation-id="operation._id" />
        </template>
      </operation-list>
    </ag-card-container>
    <ag-card-container
      v-if="unorderOperationList.length"
      flat
      fluid
      title="Opérations parrallèles"
      no-content-padding
    >
      <operation-list
        v-model="unorderOperationList"
        @input="update(unorderOperationList)"
      >
        <template v-slot:header="{ operation }">
          <v-row align="center">
            <v-col class="shrink">
              <v-icon
                class="sortable-handler"
                style="cursor: move;"
              >
                drag_indicator
              </v-icon>
            </v-col>
            <v-col>
              {{ operation.operationType.name }}
              <ag-confirm-dialog
                title="Confirmation"
                :message="removeMessage(operation)"
                cancel-label="Annuler"
                confirm-label="Supprimer"
                validation-btn-color="error"
                @confirm="removeOperation(operation)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="loadingRemove"
                    color="error"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </template>
              </ag-confirm-dialog>
            </v-col>
          </v-row>
        </template>
        <template v-slot:content="{ operation }">
          <operation-update-container :operation-id="operation._id" />
        </template>
      </operation-list>
    </ag-card-container>
    <operation-add-container
      v-if="addOperationDialog"
      :order-operation-length="orderOperationList.length"
      :unorder-operation-length="unorderOperationList.length"
      @back="addOperationDialog = false"
      @add="addOperationDialog = false; fetch();"
    />
    <ag-float-button-container style="z-index:1">
      <v-btn
        color="accent"
        fab
        @click="addOperationDialog = true"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </ag-float-button-container>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import AgCardContainer from 'src/components/common/AgCardContainer'
import OperationList from '../../components/OperationList'
import OperationUpdateContainer from './OperationUpdateContainer'
import OperationAddContainer from './OperationAddContainer'

export default {
  name: 'PartNumberUpdateRountingContainer',
  components: {
    AgFloatButtonContainer,
    OperationList,
    OperationUpdateContainer,
    AgConfirmDialog,
    AgCardContainer,
    OperationAddContainer,
  },
  props: {
    partNumberId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    loadingRemove: false,
    orderOperationList: [],
    unorderOperationList: [],
    addOperationDialog: false,
  }),
  watch: {
    $route: {
      immediate: true,
      async handler () {
        this.fetch()
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    removeMessage (operation) {
      return `Souhaitez-vous supprimer l'opération ${operation.operationType.name} ?`
    },
    async fetch () {
      try {
        const operationList = await Repositories.operation.getList(
          {
            partNumber: this.partNumberId,
            archived: false,
          },
        )
        this.orderOperationList = operationList
          .filter(operation => !operation.operationType.control)
          .sort((a, b) => a.order - b.order)
        this.unorderOperationList = operationList
          .filter(operation => operation.operationType.control)
          .sort((a, b) => a.order - b.order)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
    },
    async update (list) {
      try {
        this.loading = true
        const requests = []
        list.forEach(operation => {
          requests.push(Repositories.operation.patch({
            _id: operation._id,
            order: operation.order,
          }))
        })
        await Promise.all(requests)
        this.showSuccessNotification('La modification de l\'ordre des opérations est validé')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
    async removeOperation (operation) {
      try {
        this.loadingRemove = true
        await Repositories.operation.deleteOne(operation._id)
        this.showSuccessNotification(`Vous avez supprimé l'operation ${operation.operationType.name}`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingRemove = false
        this.newOrderNumberAfterRemoveOperation()
      }
    },
    async newOrderNumberAfterRemoveOperation () {
      await this.fetch()
      const tempList = this.orderOperationList.map((ope, index) => {
        ope.order = index + 1
        return ope
      })
      this.orderOperationList = tempList
      this.update(tempList)
    },
  },
}
</script>
