<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <v-autocomplete
        v-model="internalValue.operationType"
        :rules="$options.rules.operationType"
        label="Opération"
        :items="operationTypeList"
        item-text="name"
        return-object
        @input="change"
      />
    </v-form>
  </div>
</template>

<script>
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'

export default {
  name: 'OperationForm',
  components: { },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    internalValue: null,
    mainFormValid: false,
    operationTypeList: [],
  }),
  computed: {
    isValidOperation () {
      return this.mainFormValid
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
        this.$emit('validate', this.isValidOperation)
      },
    },
    isValidOperation: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidOperation)
      },
    },
  },
  async mounted () {
    this.operationTypeList = await Repositories.operationType.getList({ archived: false })
  },
  methods: {
    change () {
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidOperation)
    },
  },
  rules: {
    operationType: [
      FormRules.required('requis'),
    ],
  },
}
</script>
