<template>
  <div>
    <v-card v-if="value && value.partsRecords.length" flat tile>
      <v-toolbar dark color="primary" dense>
        <v-spacer />
        <v-toolbar-title>Relevés pour chaque pièce</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <record-list
          :value="value.partsRecords"
          :loading="loading"
          @input="updatePartsRecord"
          @delete="deletePartsRecord"
        />
      </v-card-text>
    </v-card>
    <v-card v-if="value && value.batchRecords.length" flat tile>
      <v-toolbar dark color="primary" dense>
        <v-spacer />
        <v-toolbar-title>Relevés de l'opération</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <record-list
          :value="value.batchRecords"
          :loading="loading"
          @input="updateBatchRecords"
          @delete="deleteBatchRecords"
        />
      </v-card-text>
    </v-card>
    <v-btn
      tile
      color="secondary"
      block
      @click="addRecordDialog = true"
    >
      Ajouter des relevées
    </v-btn>
    <record-add-container
      v-if="addRecordDialog"
      :operation-id="operationId"
      @back="addRecordDialog = false"
      @add="addRecordDialog = false; fetch();"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import RecordList from '../../components/RecordList'
import RecordAddContainer from './RecordAddContainer'

export default {
  name: 'OperationRecordListContainer',
  components: {
    RecordList,
    RecordAddContainer,
  },
  props: {
    operationId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    value: null,
    addRecordDialog: false,
  }),
  created () {
    this.fetch()
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    async fetch () {
      try {
        this.value = await Repositories.operation.get(this.operationId)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
    },
    async updatePartsRecord (recordList) {
      try {
        this.loading = true
        await Repositories.operation.patch({ _id: this.value._id, partsRecords: recordList })
        this.showSuccessNotification('La modification de l\'ordre des relevés est validé')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
    async deletePartsRecord (recordToDelete) {
      try {
        this.loading = true
        this.value.partsRecords.splice(
          this.value.partsRecords.findIndex(record => record._id === recordToDelete._id),
          1,
        )
        await Repositories.operation.patch({
          _id: this.value._id,
          partsRecords: this.value.partsRecords,
        })
        this.showSuccessNotification('Le relevés a bien été supprimé')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
    async updateBatchRecords (recordList) {
      try {
        this.loading = true
        await Repositories.operation.patch({ _id: this.value._id, batchRecords: recordList })
        this.showSuccessNotification('La modification de l\'ordre des relevés est validé')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
    async deleteBatchRecords (recordToDelete) {
      try {
        this.loading = true
        this.value.batchRecords.splice(
          this.value.batchRecords.findIndex(record => record._id === recordToDelete._id),
          1,
        )
        await Repositories.operation.patch({
          _id: this.value._id,
          batchRecords: this.value.batchRecords,
        })
        this.showSuccessNotification('Le relevés a bien été supprimé')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
  },
}
</script>
