<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <v-textarea
        v-model="internalValue.instructions"
        filled
        label="Description"
        rows="4"
        @input="change"
      />
      <ag-switch
        v-model="internalValue.instructionsLevel"
        inset
        label="Lecture de l'information obligatoire"
        @input="change"
      />
      <ag-autocomplete
        v-model="internalValue.document"
        :rules="$options.rules.document"
        label="Document"
        :item-list="documentList"
        item-text="name"
        item-key="_id"
        @input="change"
      />
      <v-text-field
        v-model="internalValue.workload"
        :rules="$options.rules.workload"
        label="Temps de fabrication / pièce en secondes"
        type="number"
        @input="change"
      />
      <v-text-field
        v-model="internalValue.rejectionCost"
        :rules="$options.rules.rejectionCost"
        label="Coût rebut / pièce"
        type="number"
        @input="change"
      />
    </v-form>
  </div>
</template>

<script>
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'
import AgSwitch from 'src/components/common/AgSwitch'
import AgAutocomplete from 'src/components/common/AgAutocomplete'

export default {
  name: 'OperationDetailsForm',
  components: { AgSwitch, AgAutocomplete },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    internalValue: null,
    mainFormValid: false,
    documentList: [],
  }),
  computed: {
    isValidOperation () {
      return this.mainFormValid
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
        this.$emit('validate', this.isValidOperation)
      },
    },
    isValidOperation: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidOperation)
      },
    },
  },
  async mounted () {
    this.documentList = await Repositories.document.getList()
  },
  methods: {
    change () {
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidOperation)
      this.$emit('edit')
    },
  },
  rules: {
    workload: [
      FormRules.required('requis'),
      FormRules.greater('Veuilez saisir une valeur positive', { number: -1 }),
      FormRules.integer('Veuilez saisir une valeur entière'),
    ],
    rejectionCost: [
      FormRules.required('requis'),
      FormRules.greater('Veuilez saisir une valeur positive', { number: -1 }),
      FormRules.integer('Veuilez saisir une valeur entière'),
    ],
  },
}
</script>
