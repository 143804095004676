<template>
  <v-card tile class="mt-4">
    <v-tabs
      v-model="tab"
      background-color="primary"
      color="primary"
      :slider-size="5"
      slider-color="accent"
      grow
    >
      <v-tab
        v-text="'Details'"
      />
      <v-tab
        v-text="'Relevés'"
      />
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <operation-update-details-container :operation-id="operationId" />
      </v-tab-item>
      <v-tab-item eager>
        <operation-record-list-container :operation-id="operationId" />
      </v-tab-item>
    </v-tabs-items>
    <router-view />
  </v-card>
</template>

<script>
import OperationUpdateDetailsContainer from './OperationUpdateDetailsContainer'
import OperationRecordListContainer from './OperationRecordListContainer'

export default {
  name: 'OperationUpdateContainer',
  components: {
    OperationUpdateDetailsContainer,
    OperationRecordListContainer,
  },
  props: {
    operationId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tab: null,
  }),
}
</script>
