<template>
  <v-card flat>
    <v-card-text>
      <operation-details-form
        v-if="value"
        v-model="value"
        @validate="isValid = $event"
        @edit="change = true"
      />
    </v-card-text>
    <v-fade-transition>
      <v-divider v-if="change" />
    </v-fade-transition>
    <v-slide-y-transition>
      <v-card-actions v-if="change">
        <v-spacer />
        <v-btn
          :loading="loading"
          :disabled="!isValid || !value"
          outlined
          color="primary"
          class="ml-5"
          @click="fetch"
          v-text="'Annuler'"
        />
        <v-btn
          :loading="loading"
          :disabled="!isValid || !value"
          color="primary"
          class="ml-5"
          @click="update"
          v-text="'Modifier'"
        />
        <v-spacer />
      </v-card-actions>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import OperationDetailsForm from '../../components/OperationDetailsForm'

export default {
  name: 'OperationUpdateDetailsContainer',
  components: {
    OperationDetailsForm,
  },
  props: {
    operationId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isValid: false,
    loading: false,
    value: null,
    change: false,
  }),
  created () {
    this.fetch()
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    async fetch () {
      try {
        this.value = await Repositories.operation.get(this.operationId)
        this.change = false
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
    },
    async update () {
      this.loading = true
      try {
        await Repositories.operation.patch(this.value)
        this.change = false
        this.showSuccessNotification('La modification de l\'opération est validé')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
  },
}
</script>
