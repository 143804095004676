var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.orderOperationList.length)?_c('ag-card-container',{attrs:{"flat":"","fluid":"","title":"Opérations successives","no-content-padding":""}},[_c('operation-list',{on:{"input":function($event){return _vm.update(_vm.orderOperationList)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var operation = ref.operation;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink"},[_c('v-icon',{staticClass:"sortable-handler",staticStyle:{"cursor":"move"}},[_vm._v(" drag_indicator ")])],1),_c('v-col',[_vm._v(" "+_vm._s(operation.order)+" - "+_vm._s(operation.operationType.name)+" "),_c('ag-confirm-dialog',{attrs:{"title":"Confirmation","message":_vm.removeMessage(operation),"cancel-label":"Annuler","confirm-label":"Supprimer","validation-btn-color":"error"},on:{"confirm":function($event){return _vm.removeOperation(operation)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loadingRemove,"color":"error","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,true)})],1)],1)]}},{key:"content",fn:function(ref){
var operation = ref.operation;
return [_c('operation-update-container',{attrs:{"operation-id":operation._id}})]}}],null,false,2501789163),model:{value:(_vm.orderOperationList),callback:function ($$v) {_vm.orderOperationList=$$v},expression:"orderOperationList"}})],1):_vm._e(),(_vm.unorderOperationList.length)?_c('ag-card-container',{attrs:{"flat":"","fluid":"","title":"Opérations parrallèles","no-content-padding":""}},[_c('operation-list',{on:{"input":function($event){return _vm.update(_vm.unorderOperationList)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var operation = ref.operation;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink"},[_c('v-icon',{staticClass:"sortable-handler",staticStyle:{"cursor":"move"}},[_vm._v(" drag_indicator ")])],1),_c('v-col',[_vm._v(" "+_vm._s(operation.operationType.name)+" "),_c('ag-confirm-dialog',{attrs:{"title":"Confirmation","message":_vm.removeMessage(operation),"cancel-label":"Annuler","confirm-label":"Supprimer","validation-btn-color":"error"},on:{"confirm":function($event){return _vm.removeOperation(operation)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loadingRemove,"color":"error","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,true)})],1)],1)]}},{key:"content",fn:function(ref){
var operation = ref.operation;
return [_c('operation-update-container',{attrs:{"operation-id":operation._id}})]}}],null,false,1326928830),model:{value:(_vm.unorderOperationList),callback:function ($$v) {_vm.unorderOperationList=$$v},expression:"unorderOperationList"}})],1):_vm._e(),(_vm.addOperationDialog)?_c('operation-add-container',{attrs:{"order-operation-length":_vm.orderOperationList.length,"unorder-operation-length":_vm.unorderOperationList.length},on:{"back":function($event){_vm.addOperationDialog = false},"add":function($event){_vm.addOperationDialog = false; _vm.fetch();}}}):_vm._e(),_c('ag-float-button-container',{staticStyle:{"z-index":"1"}},[_c('v-btn',{attrs:{"color":"accent","fab":""},on:{"click":function($event){_vm.addOperationDialog = true}}},[_c('v-icon',[_vm._v("add")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }